<template>
    <!--begin::Wrapper-->
    <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <el-form @submit.prevent="onSubmit()" :model="form.data" ref="loginForm">
            <!--begin::Heading-->
            <div class="text-center mb-10">
                <!--begin::Title-->
                <h1 class="text-dark mb-3">{{ $t('pages.login.title') }}</h1>
                <!--end::Title-->
            </div>
            <!--begin::Heading-->
            <div class="fv-row mb-10">
                <label class="form-label fs-6 fw-bolder text-dark">{{ $t('pages.login.cols.email') }}</label>
                <el-form-item prop="email" :rules="$validation.getMessage(['requiredBlur', 'email'])">
                    <el-input v-model="form.data.email" ref="email" type="text"/>
                </el-form-item>
            </div>

            <div class="fv-row mb-10">
                <label class="form-label fs-6 fw-bolder text-dark">{{ $t('pages.login.cols.password') }}</label>
                <el-form-item prop="password" :rules="$validation.getMessage(['required'])">
                    <el-input v-model="form.data.password" ref="password" show-password/>
                </el-form-item>
            </div>

            <!--begin::Actions-->
            <div class="text-center">
                <!--begin::Submit button-->
                <button :data-kt-indicator="form.loading ? 'on' : null" type="submit" :disabled="form.loading" class="btn btn-lg btn-primary w-100 mb-5">
                    <span v-if="!form.loading" class="indicator-label">{{ $t("pages.login.btnText") }}</span>
                    <span v-if="form.loading" class="indicator-progress">
                        {{ $t("messages.wait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
                <!--end::Submit button-->
            </div>
            <!--end::Actions-->
        </el-form>
    </div>
    <!--end::Wrapper-->
</template>


<script>
import {Actions} from "@/store/enum/StoreEnums";
import * as Yup from "yup";

export default {
    layout: 'auth',
    name: "login",
    data() {
        return {
            form: {
                loading: false,
                data: {}
            },
        }
    },
    mounted() {
        this.$refs.email.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
        this.$refs.password.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
    },
    methods: {
        onSubmit() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    this.$store.dispatch(Actions.LOGOUT);

                    // Send login request
                    this.$store.dispatch(Actions.LOGIN, this.form.data).then(() => {
                        this.$root.initialize();
                        // Go to page after successfully login
                        this.$router.push({name: "dashboard"});
                        /*this.$swal.fire({
                            text: this.$t('pages.login.successLogin'),
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText:  this.$t('btn.ok'),
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(() => {
                            this.$root.initialize();
                            // Go to page after successfully login
                            this.$router.push({name: "dashboard"});
                        });*/
                    }).catch(error => {
                        let swalText = this.$store.getters.getErrors[0] == 'invalid_credentials' ? this.$t('pages.login.responseMessages.invalidCredentials') : this.$store.getters.getErrors[0];

                        if (error.data.status_code === 403) {
                            swalText = this.$t('pages.login.responseMessages.forbiddenRequest');
                        }

                        this.$swal.fire({
                            text: swalText,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: this.$t('btn.ok'),
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    }).finally(() => {
                        this.form.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        onSubmitLogin(values) {
            // Clear existing errors


        }
    }
}
</script>